import axios from "axios";
import { clearStorage, getStorage, removeStorage } from "@/utils/storage";
import { Message } from "element-ui";
import { Store } from "vuex";
// 创建一个axios的实例
const Axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

// 添加请求拦截
Axios.interceptors.request.use(
  (config) => {
    const token = getStorage("token");
    config.headers["Access-Token"] = token;
    if (config.url == "/nodeservice/addLog") {
      config.url = "https://www.caoss.vip" + config.url;
    } else {
      config.url = process.env.VUE_APP_BASE_API + config.url;
    }
    return config;
  },

  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器

Axios.interceptors.response.use(
  (res) => {
    console.log("同时请求成功次数");
    let { data } = res;
    if (typeof data == "string") {
      data = JSON.parse(data);
    }
    if (data.code == 400) {
      Message({
        message: data.msg || data.message || data.reason,
        type: "error",
        duration: 2 * 1000,
      });
      return;
    }
    if (
      data.code == 200 ||
      (res.status == 200 && data.status != 500 && data.code != 500)
    ) {
      return res.data;
    } else if (data.code == 401 || data.code == 599) {
      setTimeout(() => {
        if (getStorage("token")) {
          Message({
            message: "登录信息已过期",
            type: "error",
            duration: 2 * 1000,
          });
          clearStorage();
          Store.commit("user/resetLoginInfo");
        } else {
          Message({
            message: "请先进行登录",
            type: "error",
            duration: 2 * 1000,
          });
        }
      }, 200);
      clearStorage();
    } else if (data.code == 500) {
      Message({
        message: data.msg || data.message,
        type: "error",
        duration: 2 * 1000,
      });
      return Promise.reject(data);
    } else {
      Message({
        message: data.msg || data.message || data.reason,
        type: "error",
        duration: 2 * 1000,
      });
    }
  },

  (err) => {
    let status = err.response.status;
    if (status == 401) {
      removeStorage("token");
      Message({
        message: "登录信息已过期",
        type: "error",
        duration: 2 * 1000,
      });
      clearStorage();
      Store.commit("user/resetLoginInfo");
    } else {
      Message({
        message: err.toString(),
        type: "error",
        duration: 2 * 1000,
      });
    }
    return Promise.reject(err.toString());
  }
);

export default Axios;
