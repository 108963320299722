import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/homeIndex.vue"),
      },
      {
        path: "/userInfo",
        name: "userInfo",
        component: () => import("../views/userInfo.vue"),
        meta: {
          login: true,
        },
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../views/register.vue"),
      },
      {
        path: "/citySelect",
        name: "citySelect",
        component: () => import("../views/citySelect.vue"),
      },
      {
        path: "/companyInfo",
        name: "companyInfo",
        component: () => import("../views/companyInfo.vue"),
      },
      {
        path: "/noticeDetails/:id",
        name: "noticeDetails",
        component: () => import("../views/noticeDetails.vue"),
      },
      {
        path: "/jobListView",
        name: "jobListView",
        component: () => import("../views/jobListView.vue"),
      },
      {
        path: "/workerListView",
        name: "workerListView",
        component: () => import("../views/workerListView.vue"),
      },
      {
        path: "/jobDetailView/:id",
        name: "jobDetailView",
        component: () => import("../views/jobDetailView.vue"),
      },
    ],
  },
  {
    path: "/guider",
    name: "guider",
    component: () => import("../views/guider.vue"),
    meta: {
      title: "千万职联",
    },
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
export default router;
