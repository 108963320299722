import { getStorage } from "@/utils/storage";
const user = {
  namespaced: true, // 添加 namespaced 属性
  state: {
    login_state: "",
    login_user: "",
    login_avator: "",
    city_label: "",
    city_value: "",
    download_state: false,
  },
  mutations: {
    changeDownloadState(state, data) {
      state.download_state = data;
    },
    resetLoginInfo(state) {
      state.login_state = getStorage("token");
      state.login_user = getStorage("userName");
      state.login_avator = getStorage("userAvator");
    },
    getPosition(state) {
      state.city_label = getStorage("selectCityLabel");
      state.city_value = getStorage("selectCityValue");
    },
  },
  actions: {
    checkToken(content, data) {
      return new Promise((resolve, reject) => {
        let that = data._this;
        that.$api
          .checkToken()
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            content.state.login_state = "";
            content.state.login_user = "";
            content.state.login_avator = "";
            reject(err);
          });
      });
    },
  },
  getters: {},
};

export default user;
