import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import api from "@/utils/api.js";
import { getStorage } from "@/utils/storage";
Vue.prototype.$api = api;
Vue.use(ElementUI);
Vue.config.productionTip = false;
console.log(process.env);
let app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
  console.log(11111111);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.login && !getStorage("token")) {
    app.$message.error("请先进行登录");
    return;
  }
  if (to.meta.login && getStorage("token")) {
    store.dispatch("user/checkToken", { _this: app }).then((res) => {
      if (res === "OK") {
        next();
      } else {
        app.$message.error("登录信息已过期，请重新登录");
      }
    });
  } else {
    next();
  }
});

