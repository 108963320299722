import request from "./request.js";

export default {
  loginWithSmscode(data) {
    return request.post("/ums/app/webloginpc", data);
  },
  getSmscode(data) {
    return request.post("/comm/comsmsapi/phcode", data);
  },
  loginWithPassword(data) {
    return request.post("/ums/app/loginpw", data);
  },
  getSwiperList(data) {
    return request.post("/main/web/cals", data);
  },
  getJobList(data) {
    return request.post("/main/appjob/query", data);
  },
  getJobTypeTree(data) {
    return request.post("/main/job/tptree", data);
  },
  getDictionary(data) {
    return request.post("/comm/comapi/dictionary", data);
  },
  doSuggestion(data) {
    return request.post("/main/appusr/cplsave", data);
  },
  userRegister(data) {
    return request.post("/ums/app/reguserph", data);
  },
  getAddressStep(data) {
    return request.get("/shop/city_list_pid?pid=" + data.pid);
  },
  wkexpsave(data) {
    return request.post("/main/appwk/wkexpsave", data);
  },
  checkLicense(data) {
    return request.post("/comm/comocrapi/bzlicense", data);
  },
  saveCompanyInfo(data) {
    return request.post("/main/appcpy/webapply", data);
  },
  getNoticeList(data) {
    return request.post("/main/web/notices", data);
  },
  getNoticeDetails(data) {
    return request.post("/main/web/noticeinfo", data);
  },
  searchKeys(data) {
    return request.post("/main/web/searchkeys", data);
  },
  getJobListFilter(data) {
    return request.post("/main/web/queryjobs", data);
  },
  checkToken() {
    return request.post("/comm/comapi/testing", {});
  },
  getJobListNoToken(data) {
    return request.post("/main/web/jobs", data);
  },
  getSearchCitys(data) {
    return request.post("/main/web/searchcitys", data);
  },
  getWorkerTypes(data) {
    return request.post("/main/web/workjobtypes", data);
  },
  getWorkersHot(data) {
    return request.post("/main/web/workers", data);
  },
  getWorkerList(data) {
    return request.post("/main/web/queryworkers", data);
  },
  getJobDetail(data){
    return request.post("/main/web/jobdetail",data)
  }
};
